.textJustified{
  text-align: justify;
}

.bioP {
  font-size: 14px;
  padding: 20px 30%;
}

.insideTextBottom {
  display: flex;
}

.insideTextBottom span {
  align-self: flex-end;
}

.insideTextBottom a {
  align-self: flex-end;
}

.wrapOnMobile {
  text-wrap: nowrap;
}

.verticalLine {
  border-left: thin solid #fafafa;
}

.bottomBorder {
  width: 100%;
  border-bottom: thin solid #fafafa;
  margin: 0 1px;
}

.bottomBorderBlack {
  border-bottom: thin solid #0f0f0f;
}

.partsWrapper {
  padding: 20px 30% 100px 30%
}

.pieceImgHome {
  width: 100%;
  display: flex;
}

.pageContainer {
  display: flex;
  height: 100vh;
  background-color: #fafafa;
  font-size: 14px;
}

.leftNav {
  padding: 30px;
  width: 14%;
  display: inline-block;
  background-color: #0f0f0f;
  text-align: left;
  border-right: 1px solid #fafafa;
}

.mainContent {
  width: 100%;
  background-color: #0f0f0f;
}

.highlightContent {
  padding: 20px;
  padding-top: 10px;
  display: inline-block;
  background-color: #fafafa;
  color: #0f0f0f;
  border: 1px solid #0f0f0f;
  margin-left: 10px;
  margin-right: 10px;
  width: 44%;
  max-width: 44%;
  min-width: 44%;
}

.highlightContentBlack {
  padding: 20px;
  padding-top: 10px;
  display: inline-block;
  background-color: #0f0f0f;
  border: 1px solid #0f0f0f;
  margin-left: 10px;
  margin-right: 10px;
  width: 44%;
  max-width: 44%;
  min-width: 44%;
}

.highlightContentWide {
  padding: 20px;
  padding-top: 10px;
  display: inline-block;
  background-color: #0f0f0f;
  border: 1px solid #0f0f0f;
  margin-left: auto;
  margin-right: auto;
}


.collectionTitle {
  font-size: 34px;
  margin-bottom: 10px;
  margin-top: 5px;
}

.aBitBiggerText {
  font-size: 20px;
}

.coupleCollections {
  display: flex;
  padding: 10px;
}

.collectionLeft {
  padding: 20px;
  width: 50%;
}

.collectionRight {
  padding: 20px;
  width: 50%;
}

.black {
  color: #0f0f0f
}

.blackBg {
  background-color: #0f0f0f
}

.collectionPieceTitle {
  margin-top: 40px;
  font-size: 20px;
  font-weight: bold;
}

a {
  color: #fafafa;
}

a:hover {
  color: #edb2e5;
}

.pieceImg {
  margin-left: auto;
  margin-right: auto;
  max-height: 500px;
  display: block;
}

.pieceImgWide {
  margin-left: auto;
  margin-right: auto;
  height: auto;
  max-height: none;
  width: 70%;
  display: block;
}

.pieceImgGen {
  margin-top: 40px;
  margin-left: auto;
  margin-right: auto;
  max-height: 500px;
  display: block;
}

.pieceImgSquared {
  max-height: 500px;
  width: -webkit-fill-available;
}

.poemInCollection {
  width: 70%;
  margin: 40px auto;
}

.manifestoImgsWrapper {
  display: flex;
}

.manifestoImg {
  max-height: 400px;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.manifestoP {
  padding: 50px;
}

.desktopHalfMobileFullWidth {
  width: 50%;
}

@media screen and (max-width: 1120px) {

  .bioP {
    padding: 20px 5%;
  }

  .wrapOnMobile {
    text-wrap: wrap;
  }

  .verticalLine {
    border-left: none;
  }

  .desktopHalfMobileFullWidth {
    width: 100%;
  }

  .partsWrapper {
    padding: 20px 5% 100px 5%
  }

  .pageContainer {
    display: block;
  }

  .leftNav {
    width: 100%;
    display: block;
    border-right: none;
    box-sizing: border-box;
  }

  .mainContent {
    width: 100%;
  }

  .coupleCollections {
    display: flex; 
    flex-direction: column;
    padding: 0;
  }

  .pieceImg {
    height: auto;
    max-height: none;
    width: 70%;
  }

  .pieceImgGen {
    height: auto;
    max-height: none;
    width: 70%;
  }

  .pieceImgSquared {
    margin-left: auto;
    margin-right: auto;
    display: block;
    max-height: none;
    width: 70%;
  }

  .pieceImgWide {
    width: 100%;
  }

  .aBitBiggerText {
    font-size: 16px;
  }

  .highlightContent {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    margin: 0;
    box-sizing: border-box;
  }

  .highlightContentBlack {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    margin: 0;
    box-sizing: border-box;
  }

  .highlightContentWide {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    margin: 0;
    box-sizing: border-box;
  }

  .collectionTitle {
    font-size: 24px;
    margin-bottom: 10px;
    margin-top: 5px;
  }

  .collectionLeft {
    padding: 20px;
    width: 100%;
    box-sizing: border-box;
  }

  .collectionRight {
    padding: 20px;
    width: 100%;
    box-sizing: border-box;
  }

  .sketchWrapper {
    width: 100%;
    max-width: 100%;
    margin: auto;
  }

  .manifestoImgsWrapper {
    display: block;
  }

  .manifestoImg {
    height: auto;
    max-height: none;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 40px;
    margin-top: 40px;
    display: block;
  }

  .manifestoP {
    padding: 0;
  }

}